html {  
  cursor: url(../src/imgs/paw.png) !important;
}

html, body {
  width: 100%;
  height: 100%;
  background-color: yellow;
}

div {
  cursor: url(../src/imgs/paw.png), auto !important;
}

a {
  cursor: url(../src/imgs/paw.png), auto !important;
}